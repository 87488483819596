<template>
<div class="overflow-x-hidden max-w-screen" style="max-width:100vw; overflow-x:hidden;">

  <div id="welcome" style="transform:translateY(-55px)"></div><!-- the anchor for smooth scroll to the top of the page using <a> links -->

  <!-- <navbar></navbar> -->

  <!-- this router-view keeps components alive -->
  <!-- <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view> -->



  <!-- this router-view loads a fresh component instance each time we navigate -->
  <router-view></router-view>


</div>
</template>




<script>

import { defineAsyncComponent } from 'vue'


export default
{
  components: {

  },
  // updated() {
  //   this.$nextTick(() => {
  //     if(this.$route.hash) {
  //       const el = document.querySelector(this.$route.hash);
  //       el && el.scrollIntoView();
  //     }
  //   })
  // },
  watch: {
    $route() {
      //console.log("a vue route was clicked");
    },
  },
  beforeMount()
  {

  },
  mounted()
  {

  }
}
</script>



<style>

@import url('https://fonts.googleapis.com/css?family=Rubik:500,700,900');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;700&display=swap');

body {
  @apply flex;
}

#app {
  background:white;
  max-width:100vw;
  overflow-x: hidden;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.navbar-padding {
  padding-top:55px;
}

.router-link {
  @apply text-xs font-semibold tracking-tighter cursor-pointer;
}



</style>
