<template>
<div id="landing-page" class="overflow-x-hidden max-w-screen">

    <navbar-zimmer class=""></navbar-zimmer>

    <div id="cover-page" class="full-page-container items-center justify-center z-0">

      <video playsinline autoplay loop muted poster="video/cover.jpg" preload="none" id="bgvid">
        <source src="video/intro_1.mp4" type="video/mp4">
      </video>

      <div class="tinted-overlay">

      <div class="section-wrapper">

        <div class="background-image-container z-0">

          <div id="company-logo" class="z-10 opacity-0">
            <img src="img/Zimmer_Audio_fv7_small.png" class="company-png"/>
          </div>

        </div>

        <div id="company-subtext" class="flex justify-center -mb-24 md:-mb-36 opacity-0">
          <h2 class="subtext">SOUND & MUSIC PRODUCTION</h2>
        </div>

        <div class="hidden flex justify-center mt-4">
          <h3 class="">Iconic sounds,  charming melodies, and audio that goes beyond one dimension.</h3>
        </div>

        <!-- <p class="mt-12">Choose One</p>
        <div class="background-picker block grid-cols-4 mt-4 gap-4 flex justify-center">
          <button @click="changeBackgroundYellow()" class="focus:outline-none h-10 w-10 bg-amber-300 rounded-full"></button>
          <button @click="changeBackgroundOrange()" class="focus:outline-none h-10 w-10 bg-orange-300 rounded-full"></button>
          <button @click="changeBackgroundGreen()" class="focus:outline-none h-10 w-10 bg-green-300 rounded-full"></button>
          <button @click="changeBackgroundBlue()" class="focus:outline-none h-10 w-10 bg-cyan-300 rounded-full"></button>
        </div> -->

        <!-- <div class="hidden mt-4">
          <div class=" w-screen h-48 border-2 border-orange-200"></div>
        </div> -->

        <div id="cover-page-down-arrow" class="opacity-0 bounce text-2xl w-full text-center left-0 bottom-0 pb-4 absolute text-white">
          &darr;
        </div>



        </div>
      </div>
    </div>


    <Clients v-if="loadClients == true"></Clients>

    <Showreel v-if="loadShowreel == true"></Showreel>

    <Services v-if="loadServices == true"></Services>

    <News v-if="loadNews == true"></News>

    <contact-form></contact-form>


</div>
</template>

<script>
import $ from 'jquery';

import { defineAsyncComponent } from 'vue'
// Async component without options
const NavbarZimmer = defineAsyncComponent(() => import('../components/NavbarZimmer.vue'))
const Clients = defineAsyncComponent(() => import('./Clients.vue'))
const Showreel = defineAsyncComponent(() => import('./Showreel.vue'))
const Services = defineAsyncComponent(() => import('./Services.vue'))
const News = defineAsyncComponent(() => import('./News.vue'))
const ContactForm = defineAsyncComponent(() => import('./ContactForm.vue'))


import {
    gsap
} from "gsap";
import {
    ScrollToPlugin
} from "gsap/ScrollToPlugin";
import {
    ScrollTrigger
} from "gsap/ScrollTrigger";
import {
    DrawSVGPlugin
} from "gsap/DrawSVGPlugin";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, DrawSVGPlugin);


export default {
  components: {
    NavbarZimmer, Clients, Showreel, Services, News, ContactForm,
  },

  data() {
    return {
      loadClients: true,
      loadShowreel: true,
      loadServices: true,
      loadNews: true,
      backgroundImageGradient: 'linear-gradient(0deg, #d9fcff, #c3ffeb)',
    }
  },
  beforeMount()
  {

  },
  mounted()
  {

    // window.onbeforeunload = function () {
    //   window.scrollTo(0, 0);
    // }

    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        //console.log('Page completed with image and files!')
        // fetch to next page or some code
        this.loadClients = true;
        this.loadShowreel = true;
        this.loadServices = true;
        this.loadNews = true;
      }
    }



    // a timeline for the cover page scrolling-out animation
    var tlCoverOut = gsap.timeline();

    tlCoverOut.fromTo("#company-logo", { opacity: 1, y:0 }, { opacity:0, y:-50, duration:0.3 })
    tlCoverOut.fromTo("#company-subtext", { opacity: 1, y:0 }, { opacity:0, y:-50, duration:0.4})


    ScrollTrigger.create({
      trigger: "#cover-page",
      start: "+55px top", // when x of the trigger hits x on the viewport
      end: "bottom center", // the bottom of the trigger hits center of the viewport
      //pin: true,
      //pinSpacing: true,
      animation: tlCoverOut,
      scrub: true,
      //markers:true,
    });


  },
  methods: {

    changeBackgroundYellow()
    {
      gsap.to("#landing-page", {backgroundPosition:'0 0', duration: 1});
      //this.backgroundImageGradient = 'linear-gradient(0deg, #f8ffd9, #fcffc3)';
    },
    changeBackgroundOrange()
    {
      this.backgroundImageGradient = 'linear-gradient(0deg, #ffefd9, #ffe7c3)';
    },
    changeBackgroundGreen()
    {
      // this.backgroundImageGradient = 'linear-gradient(0deg, #d9fcff, #c3ffeb)';

      gsap.to("#landing-page", {backgroundPosition:'0 100', duration: 1});
    },
    changeBackgroundBlue()
    {
      this.backgroundImageGradient = 'linear-gradient(0deg, #d9e9ff, #c3f8ff)';
    },
  }
}
</script>

<style class="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;700&display=swap');


/* html {
  scroll-behavior: smooth;
} */

#landing-page {
  /* background-image: linear-gradient(0deg, #d9fff4, #c3ffeb, #ffefd9, #ffe7c3); */
  /* background-size: auto 300%;
  background-position: 0 0%; */
}



#bgvid
{
  @apply absolute h-full w-full top-0;
  object-fit: cover;
}

/* .viewport-header {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
} */


.tinted-overlay {
  @apply h-full w-full absolute top-0 flex items-center;
  /* background:rgba(5,5,5,0.5); */
  /* background: linear-gradient(90deg, rgba(0,37,84,0.9) 0%, rgba(26,57,113,0.9) 35%, rgba(0,92,116,0.9) 100%); */
  background: linear-gradient(325deg, rgba(0,37,84,0.8) 0%, rgba(0,94,129,0.95) 35%, rgba(0, 99, 190, 0.8) 100%);
    /* background:linear-gradient(to right,rgba(65, 0, 255, 0.5),rgba(255, 0, 232, 0.5)); */
}

.background-image-container {
  @apply flex items-center justify-center z-0;
}

.background-image-container > img {
  @apply h-2/5 mx-auto absolute;
  transform:translateY(-75%);
}

.min-h-screen-minus-nav {
  min-height: calc(100vh - 55px);
}

h1 {
  @apply uppercase mb-4;
  font-family: 'Rubik', sans-serif;
  font-size: 4rem;
}

.client-logo {
   @apply items-center justify-center flex;
}

.full-page-container
{
  @apply flex min-h-screen-minus-nav w-screen px-4 pt-16 text-left;
}

.page-container
{
  @apply flex w-screen px-4 pt-16 text-left;
}
.centered
{
  @apply items-center justify-center;
}

.section-wrapper
{
  @apply sm:px-24 lg:px-48 xl:px-48 mx-auto w-full;
}

.section-wrapper-wider
{
  @apply sm:px-12 lg:px-24 xl:px-24 mx-auto w-full;
}

.section-wrapper-widest
{
  @apply sm:px-4 lg:px-8 xl:px-8 mx-auto w-full;
}

.background-gradient-yellow {
  /* background-image: linear-gradient(0deg, #289cbd, #5ee9ba, #52d6a9); */
  background-image: linear-gradient(0deg, #5ef1ff, #90eece);
  /* background-image: linear-gradient(0deg, #d9fcff, #c3ffeb); */
}

.background-gradient-green {
  /* background-image: linear-gradient(0deg, #289cbd, #5ee9ba, #52d6a9); */
  /* background-image: linear-gradient(0deg, #5ef1ff, #90eece); */
  background-image: linear-gradient(0deg, #d9fcff, #c3ffeb);
}

.text-gradient {
  @apply relative leading-none bg-clip-text bg-gradient-to-r from-gray-100 to-gray-200 text-5xl font-black;
  color:transparent;
}


h2 {
  @apply text-center;
  /* font-family: 'Andale Mono', 'Source'; */
  font-family: Helvetica;
}

.company-png
{
  /* width:70vw; */
  /* max-width:800px; */
  @apply m-0 p-0 mb-8 mx-auto w-3/4 max-w-4xl;
  transform:translateY(15%);
}

.subtext {
  @apply text-sm md:text-xl mt-0 md:mt-4 border-t-2 border-b-2 border-cyan-300 p-2 text-white;
  font-family:'Source Code Pro', 'Helvetica';
}

@media(max-width: 767px)
{
  .subtext
  {
    letter-spacing: 5px;
  }

}

@media(min-width: 768px)
{
  .subtext
  {
    letter-spacing: 10px;
  }
}


.news-box {
  @apply border-4 p-2;
}

.news-box-image {
  min-height: 200px;
}

.news-box h3 {
  @apply font-bold;
}

.news-text {

}

.person-image
{
  @apply h-full w-auto bg-gray-100 opacity-0;
}

.studio-image
{
  @apply h-full w-auto bg-blueGray-200 opacity-0;
}

#cover-page {
  /* @apply bg-gradient-to-b from-blue-100 via-gray-100 to-gray-300; */
  /* @apply bg-gradient-to-b from-gray-900 to-gray-700; */
  margin-top:55px;
}

#our-work {
  /* @apply bg-gradient-to-b from-gray-300 to-orange-100; */
  @apply bg-white;
}

#services {
  /* @apply bg-gradient-to-b from-orange-100 to-orange-100; */
}


.news-link {
  @apply text-cyan-400 hover:text-cyan-200 cursor-pointer font-semibold;
}

.services-box-header {
  @apply text-3xl normal-case opacity-0;
}

@media(min-width:768px)
{
  /* .services-box-header {
    text-shadow: 1px 2px #007e73;
  } */
}


</style>
