import { createStore } from 'vuex'
import auth from './auth'

export default createStore({
  state: {
    message: 'Hey, man',
  },
  mutations: {

  },
  actions: {

  },
  modules: {
    auth
  }
})
