<!-- using formspree.io as an endpoint-->
<template>

<!-- the root div class for the component -->
<div>

<div id="contact" class="contact-form-container" style="">

  <!-- the full screen overlay which fades in when the status variable property changes -->
  <transition name="fade">
    <div v-show="status == 'submitting' || status == 'successful'" class="loading-overlay">

      <div v-show="status =='submitting'" class="status-badge bg-white h-40 w-40 flex justify-center rounded-full">
        <svg class="animated-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <circle class="circle-stroke" r="400" fill="none" cx="500" cy="500" stroke="#ffcc00" stroke-width="20"/>
        </svg>
        <h2 id="status-text-sending" class="font-bold text-2xl self-center text-gray-700">Sending...</h2>
      </div>

      <div v-show="status =='successful'" class="status-badge bg-white h-40 w-40 flex justify-center rounded-full">
        <svg class="animated-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <circle class="circle-stroke" r="400" fill="none" cx="500" cy="500" stroke="#ffcc00" stroke-width="20"/>
        </svg>
        <h2 id="status-text-success" class="font-bold text-2xl self-center text-green-500">Success!</h2>
      </div>

    </div>
  </transition>

  <!-- the contact form which prevents the default submit and instead triggers the function onSubmitForm -->
  <form 
    id="contact-form" class="" 
    method="POST"
    action="https://formspree.io/f/mvoenbpy" @keydown="form.errors.clear($event.target.name)">

    <!-- the title header -->
    <div class="form-top">
      <div v-show="status != 'complete'" class="header-message">
        <h1 class="form-header text-heading">CONTACT US</h1>
        <h5 class="subheader text-subheading">Send us a message below.</h5>
      </div>
      <!-- which changes to a thank you message when the status property changes to 'complete' -->
      <div v-show="status == 'complete'" class="thank-you-message">
        <h1 id="" class="form-header">Thanks!</h1>
        <h5 class="subheader">We appreciate hearing from you.</h5>
      </div>
    </div>

    <div id="contact" class="flex flex-wrap">

        <!-- the name input -->
        <div v-show="status != 'complete'" class="w-full px-3">
          <label class="form-label" for="name">
            Your Name
          </label>
          <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
            v-bind:css="false"
            >
            <p class="form-warning" v-if="form.errors.has('name')">{{form.errors.get('name')}}</p>
          </transition>
          <input required class="form-input" id="name" name="name" type="text" autocomplete="off" v-model="form.name"/>
        </div>

        <!-- the email input -->
        <div v-show="status != 'complete'" class="w-full px-3">
          <label class="form-label" for="email">
            Your Email
          </label>
          <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
            v-bind:css="false"
            >
            <p class="form-warning" v-if="form.errors.has('email')">{{form.errors.get('email')}}</p>
          </transition>
          <input required class="form-input" id="email" name="email" type="email"  autocomplete="off" v-model="form.email"/>
        </div>

        <!-- the message input -->
        <div v-show="status != 'complete'" class="w-full px-3">
          <label for="message" class="form-label" style="">Message</label>

          <transition v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:leave="leave" v-bind:css="false">
            <p class="form-warning" v-if="form.errors.has('message')"></p>
          </transition>

          <textarea required id="contact-form-text-area" class="form-textarea text-lg" style="resize: none;" v-on:keyup="countMessageChars" v-model="form.message" maxlength="300" rows="3" name="message" autocomplete="off"></textarea>
          <p class="text-char-count font-normal">
            <animated-integer v-bind:value="maxMessageLength"></animated-integer>
          </p>
        </div>

        <!-- the submit form button which is disabled under certain conditions -->
        <button v-show="status != 'complete'" type="submit" class="form-button text-sm sm:text-base" :disabled="form.errors.any() || status==='submitting' || status == 'complete' || status == 'successful'">
          <span v-if="status === 'submitting'">
            Sending
          </span>
          <span v-else>
            Send <span class="pl-1">&#8674;</span> <!-- right arrow -->
          </span>
        </button>

    </div>

  </form>


  <!-- the reload form button which shows after the status changes to complete -->
  <button v-show="status == 'complete'" type="submit" class="reset-form-button" @click="reloadForm" :disabled="status != 'complete'">
    <span class="">
      Send another message?
    </span>
  </button>


  <div class="self-end pb-4 mt-12">

    <div class="w-full pointer-events-none">
      <h4 class="text-gray-700 text-sm text-center font-medium">&copy;{{new Date().getFullYear()}} ZimmerAudio.com</h4>
    </div>
    <div class="w-full pointer-events-none -mt-1">
      <span class="text-gray-500 text-center font-medium" style="font-size:11px;">Designed by Nearly Real Studios</span>
    </div>

    <div class="w-full -mt-1">
      <router-link to="/privacy-policy" class="router-link">Privacy Policy</router-link>
      &nbsp•&nbsp
      <router-link to="/terms-of-use" class="router-link">Terms of Use</router-link>
    </div>

  </div>

</div>
</div>
</template>

<script>
import * as API from "@/services/API";

import axios from 'axios';
import { gsap } from "gsap";

// Import my custom components
import AnimatedInteger from '../components/AnimatedInteger.vue'



// Two custom classes I created, class Errors and class Form, to act as an object to handle form errors returned from the server
class Errors {
    constructor()
    {
      // an object to hold error data returned in the axios request
      this.errors = {};
    }
    get(field)
    {
      if (this.errors[field])
      {
        return this.errors[field][0];
      }
    }
    record(errors)
    {
      this.errors = errors;
    }
    has(field) {
      return this.errors.hasOwnProperty(field);
    }
    any() {
      return Object.keys(this.errors).length > 0;
    }
    clear(field)
    {

    }
}

class Form {
  constructor(data) {

    this.originalData = data;

    // Loop through each key in the object passed through this constructor as (data)
    for (let field in data) {
      // save the field in vue component data as, for instance, something like form[email]
      this[field] = data[field];
    }

    // save the errors
    this.errors = new Errors();
  }

  data() {

    let data = Object.assign({}, this);

    delete data.originalData;
    delete data.errors;

    return data;
  }

  reset() {

    for (let field in this.originalData)
    {
      this[field] = '';
    }

    this.errors.clear();
  }

}

// The actual vue component class is exported below so it can be accessed as an import
export default {
    name: 'contact-form',
    components: { AnimatedInteger },
    beforeCreate()
    {

    },
    data() {
        return {
            maxMessageLength: 300,
            status: "",
            sucessfulServerResponse: "",
            serverError: "",
            form: new Form({
              name: '',
              email: '',
              message: '',
            }),
            loaderTimeline: gsap.timeline({})
        }
    },
    created()
    {

    },
    mounted()
    {
      this.countMessageChars();
    },
    methods: {

      /**** METHODS FOR THE CONTACT FORM CUSTOM ERROR LABEL TRANSITION *************/
      beforeEnter: function (el)
      {
         gsap.set(el, { y:15, opacity: 0 });
      },
      enter: function (el, done)
      {
         gsap.to(el, { y: 0, duration: 0.1, opacity:1, onComplete: done });
      },
      leave: function (el, done)
      {
         var timeline = gsap.timeline({});
         timeline.to(el, { x: 15, duration: 0.1, opacity:0 });
      },
      /***** END OF CUSTOM ERROR LABEL ANIMATION FUNCTIONS ****************/

      onSubmitForm()
      {

        const self = this;

        // grecaptcha.ready(function()
        // {
        //     grecaptcha.execute('6Ldq9r4aAAAAANpsF4tSUtD_wPbA9SkW2MgpmijJ', {action: 'submit'}).then(function(token)
        //     {
        //
        //
        //     });
        // });


        // Add your logic to submit to your backend server here.

        //console.log(token);

        // changing the self.status will trigger vue component transitions that rely on v-show="status == ''"
        self.status = "submitting"; // blurred full page overlay fades in
        // console.log(self.status);

        // animate in the loading svg circle and text
        self.showLoader()


        // First get the cookie
        // axios.get('https://api.theaudiohive.com/sanctum/csrf-cookie').then(response => {

            // send axios post request to the url endpoint
            API.apiClient.post('/contact-us',
            {
              name: self.form.name,
              email: self.form.email,
              message: self.form.message,
              //recaptchaToken: token
            })
            .then(response =>
            {
              // if successful response then
              console.log(response);

              // call the function to complete the loading animation
              self.completeLoader();

            })
            .catch(err =>
            {

              if (err.response) {
                 // client received an error response (5xx, 4xx)
                 console.log(err);
               } else if (err.request) {
                 // client never received a response, or request never left
                 console.log(err);
               } else {
                 // anything else
                 console.log(err);
               }

               //self.status = "error";
               //console.log(self.status);
               //self.form.errors.record(error.response.data.errors); // if errors were returned in the response then record them to the Errors class we created
            });

      },

      // onCaptchaExpired: function () {
      //   this.$refs.recaptcha.reset();
      // },

      countMessageChars()
      {
        var length = this.form.message.length;
        this.maxMessageLength = 300 - length;
      },

      // when the button is submitted and the captcha is verified the form begins to load
      showLoader()
      {

        var self = this;

        var loaderTL = gsap.timeline({});

        gsap.set('.circle-stroke', {
          drawSVG: '0% 0%',
          transformOrigin:"50% 50%"
        });
        gsap.set('.animated-circle', {
          xPercent:-50,
          yPercent:-50
        });
        gsap.set(['.thank-you-message', '.reset-form-button'], {
          opacity:1,
          x:0,
        });

        // show the submission status badge
        loaderTL.to('.status-badge',
        {
          rotateY: '0deg',
          opacity: 1,
          x: 0,
          duration:0.1,
          ease: 'power2.inOut'
        });

        // animate the status text in
        loaderTL.fromTo('#status-text-sending', { x:25, opacity:0}, {x:0, opacity:1, duration:0.2}, "+=0.5");
        // animate the circle stroke
        loaderTL.to('.circle-stroke',{ duration:1.5, drawSVG: '0% 100%', ease: 'power4.inOut' }, "-=0.0");
      },


      // when the axios call has completed on success then this function is called
      completeLoader()
      {
        // save this vue component as a variable so I can properly access it in nested functions
        var self = this;

        var loaderTL = gsap.timeline({});

        // fade out status message animation
        loaderTL.to('#status-text-sending', { x:-25, opacity:0, duration: 0.1 })
        .add(function()
        {
          // changing the vue component status will trigger some transition elements showing success
          self.status = 'successful';
          console.log(self.status);
        });

        // then fade in the status 'success' label which is shown when the status changes
        loaderTL.fromTo('#status-text-success', { x:35, opacity:0}, { x:0, opacity:1, duration: 0.1 });

        // then finish the circle stroke animation to complete if it isn't already
        loaderTL.to('.circle-stroke', { duration: 0.5, drawSVG: '100%', stroke: '#00ff85'}, "-=0.2");

        // fade out the 'success' status badge and trigger a function on complete to reset the form variables and the status
        loaderTL.to('.status-badge', {
          duration:0.2,
          opacity: 0,
          x: -50,
          ease: 'power2.inOut',
          delay:0.5, // time to show the message before animating out
        })
        .add(function ()
        {
          // Reset the status variable for this vue component
          self.status =  "complete";
          console.log(self.status);

          // Reset the form
          self.form.errors.clear();
          self.form.reset();
          self.maxMessageLength = 300;
        });

      },

      // When the button is clicked after the form is completed and it needs to be reloaded
      reloadForm()
      {
        // set the loader stroke color back to yello
        gsap.set('.circle-stroke', { drawSVG: '100% 100%', stroke:'#ffcc00'});

        // save the vue component as a variable so it can be accessed in nested functions
        var self = this;

        var loaderTL = gsap.timeline({});

        // create a timeline animation to fade out the thank you message
        var reloadForm = gsap.timeline({});

        loaderTL.to(['.thank-you-message', '.reset-form-button'], {
          opacity:0,
          duration:0.2,
          ease: 'power2.inOut',
          stagger: -0.1,
          x:-25,
        });

        // and fade in the form again
        loaderTL.fromTo(['.header-message h1', '.header-message h5', '#contact-form label', '#contact-form input', '#contact-form textarea', '#contact-form button', '#contact-form .text-char-count'],
        {
          opacity:0,
          x:25,
        },
        {
          // reset the errors and other variables in the form
          onStart: function()
          {
            self.status = 'reloading';
            console.log(self.status);
            self.form.errors.clear();
            self.form.reset();
          },
          // then animate the blank form back in
          opacity:1,
          x:0,
          stagger:0.05,
          duration:0.3,
          ease: 'power2.out'
        }, "+=0.0")
        .add(function()
        {
          self.status = '';
          console.log(self.status + 'blank status means the form has reloaded now');

          // delete the timeline so it can be made again from the start
          self.loaderTimeline.kill();
        });

      },
    },
}
</script>


<style>


.contact-form-container {
  @apply w-screen pb-2 sm:pb-2 flex grid grid-cols-1;
  /* min-height:calc(100vh - 55px); */
}

.diagonal-box {
	/* background-image: linear-gradient(45deg, #654ea3, #eaafc8);
	transform: skewY(-11deg); */
  position:relative;
}

.diagonal-box:before {
	   position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #654ea3, #eaafc8);
    transform: skewY(-11deg);
}

.content {
	max-width: 50em;
	margin: 0 auto;
  @apply p-4 relative;
  /* TO RESET CONTENT BACK TO HORIZONTAL STRAIGHT */
	transform: skewY(11deg);
}


.dynamic-text-size-small {
  @apply text-xs sm:text-sm md:text-base w-full text-center text-gray-900 font-normal;
  letter-spacing: 0px;
}


#contact-form {
  @apply self-center w-screen max-w-2xl p-4 pt-24 mx-auto;
}

.header-message {
  @apply w-full;
}

.form-header {
  @apply uppercase text-left font-normal w-full text-gray-900 uppercase text-2xl sm:text-3xl mt-8 ml-4;
  font-family: 'Rubik', sans-serif;
}



.subheader {
  @apply text-left -mt-4 ml-4;
}

.form-label {
   @apply inline float-left uppercase text-cyan-900 text-xs font-bold mt-5;
}

.form-warning {
  @apply inline float-right text-red-500 text-left text-xs mt-5;
}


.form-input {
  @apply text-lg text-left appearance-none block w-full px-2 py-1 leading-tight border-b-2 border-gray-300 bg-gray-100;
}

.form-input:focus {
  @apply outline-none bg-gray-200 border-cyan-500;
}

.form-textarea {
  @apply appearance-none block w-full px-2 py-1 leading-tight border-b-2 border-gray-300 bg-gray-100;
}

.form-textarea:focus {
  @apply focus:outline-none bg-gray-200 border-cyan-500;
}

.form-label-light {
   @apply block uppercase text-right text-gray-500 text-xs font-bold mt-1;
}

.text-char-count {
   @apply block uppercase text-right text-gray-500 text-xs mt-1
}

.form-button {
  @apply shadow-xl text-center text-white w-full mt-6 mx-3 px-4 py-4 border-0 border-cyan-600 bg-gray-800;
}

.form-button:hover {
  @apply outline-none shadow-none border-blue-200 bg-cyan-700;
}

.form-button:focus {
  @apply outline-none shadow-none border-blue-200 bg-gray-700;
}

.form-button:disabled {
  @apply outline-none shadow-none border-gray-700 text-gray-700;
}

.button-text {
  @apply w-full justify-center text-blue-400 text-sm;
}

.button-text:focus {
  text-decoration: underline;
}

/* Change Form Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #222 inset !important;
  transition: background-color 1 ease-in-out 0s;
  @apply shadow-inner appearance-none block w-full text-white border-cyan-200 border-2 px-3 py-2 leading-tight;
}

input {
  text-align: left !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.loading-overlay {
  @apply flex w-full h-full z-20 absolute content-center items-center justify-center;
  background:transparent;
  backdrop-filter: blur(4px);
}

.animated-circle {
  @apply absolute h-56;
  left:50%;
  top:50%;
}

/* the classes applied to a <transition> wrapper component */

/* applied at the end of the transition in (also the start of the transition out) */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
  transition-delay: 0.1s;
}

/* applied at end of the transition OUT (and therefore very start of the transition in) */
.fade-enter, .fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s;
  /* transition-delay: 0.1s; */
}

.reset-form-button
{
  @apply font-semibold bg-black text-white border border-white inline flex w-56 h-12 text-sm mx-auto items-center justify-center rounded-full;
}


.thank-you-message
{
  @apply mx-auto w-full;
}


</style>
