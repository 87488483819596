<template>
  <div>
    <form action="#" method="POST" @submit.prevent="submit">
      <div>
        <input type="email" name="email" v-model="form.email" placeholder="email">
      </div>
      <div>
        <input type="password" name="password" v-model="form.password" placeholder="password">
      </div>
      <div>
        <button type="submit">SUBMIT</button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { mapActions } from 'vuex'

// axios.defaults.baseURL = "http://localhost:90"
// axios.defaults.withCredentials = true;

export default {

  data() {
    return {
      form: {
          email: '',
          password: '',
      }
    }
  },
  methods: {

    ...mapActions({
       signIn: 'auth/signIn'
     }),

     async submit () {
       await this.signIn(this.form)

       this.$router.replace({ name: 'home' })
     },

    // login() {
    //
    //   axios.get('/sanctum/csrf-cookie').then(response => {
    //
    //       // Login...
    //       //console.log(response);
    //
    //       axios.post('/login', {
    //         email: this.email,
    //         password: this.password,
    //       })
    //       .then(response => {
    //         //console.log(response);
    //         this.getUserData();
    //       })
    //       .catch(error => {
    //         alert(error.response.data.message)
    //       })
    //
    //   });
    //
    // },
    // getUserData()
    // {
    //
    //   axios.get('/api/user')
    //   .then(response => {
    //     // console.log(response);
    //     // this.email = response.data.email
    //     this.$store.dispatch('setUserData', response.data);
    //   })
    //
    //   this.$router.push('/dashboard');
    //
    // }
  }
}
</script>
