import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Landing from '../views/Landing.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Dashboard from '../views/Dashboard.vue'
import News from '../views/News.vue'
import ContactForm from '../views/ContactForm.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'

const routes = [
  {
    path: '/',
    // redirect: '/contact',
    name: 'landing',
    component: Landing
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: News
  // },
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: ContactForm
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: ContactForm
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/terms-of-use',
    name: 'terms',
    component: Terms
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Home,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: Dashboard,
  //   meta: { requiresAuth: true }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

// a navigation guard
router.beforeEach((to, from, next) => {
  // if a route has a true meta attribute for requiresAuth
  // and the vuex store authenticated variable returns false
  // then redirect back to the /login
  if (to.meta.requiresAuth == true && store.state.auth.authenticated == false) {
    next({ name: 'login' })
  }
  else next()
})




export default router
