import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import './index.css'

window.axios = require('axios');




// Async component without options
// const Navbar = defineAsyncComponent(() => import('./components/Navbar.vue'))

// Async component with options
// const asyncPageWithOptions = defineAsyncComponent({
//   loader: () => import('./NextPage.vue'),
//   delay: 200,
//   timeout: 3000,
//   errorComponent: ErrorComponent,
//   loadingComponent: LoadingComponent
// })


// Vue-Recaptcha-v3 Component from npm installed plugin
import { VueReCaptcha, useRecaptcha } from 'vue-recaptcha-v3'

const component = {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useRecaptcha()

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
    }

    return {
      recaptcha
    }
  },
  template: '<button @click="recaptcha">Execute recaptcha</button>'
}


store.dispatch('auth/retrieveUser').then(() => {
  createApp(App).use(store).use(router).use(VueReCaptcha, { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' }).mount('#app')
});

//PRODUCTION GOOGLE RECAPTCHA SITE KEY
// siteKey: 6Lc1ieEZAAAAAGEcOMf3SASWeb1kfqDGAyelADSc

// TESTING GOOGLE RECAPTCHA SITE KEY
// siteKey: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
