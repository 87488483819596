import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    }
  },

  actions: {


    async signIn ({ dispatch }, credentials) {
      // ask our client to set a CSRF cookie by hitting Laravel Sanctums endpoint
      await axios.get('/sanctum/csrf-cookie')
      // once authenticated with a CSRF cookie, post email and password to /login
      await axios.post('/login', credentials)
      // return the user details 
      return dispatch('retrieveUser')
    },

    async signOut ({ dispatch }) {
      await axios.post('/logout')

      return dispatch('retrieveUser')
    },

    retrieveUser ({ commit })
    {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }

  }
}
