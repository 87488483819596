<template>
  <div class="home">

    <form action="#" method="POST" @submit.prevent="login">

      <div>
        <input type="text" name="firstName" v-model="firstName" placeholder="first name">
      </div>
      <div>
        <input type="text" name="lastName" v-model="lastName" placeholder="last name">
      </div>
      <div>
        <input type="email" name="email" v-model="email" placeholder="email">
      </div>
      <div>
        <input type="password" name="password" v-model="password" placeholder="password">
      </div>
      <div>
        <button type="submit">SUBMIT</button>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

// axios.defaults.baseURL = "http://localhost:90"
// axios.defaults.withCredentials = true;

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      firstName: 'peter',
      lastName: 'pumkins',
      email: 'hathawayfamily86@gmail.com',
      password: 'password',
    }
  },
  methods: {
    login() {

      axios.get('/sanctum/csrf-cookie').then(response => {

          //console.log(response);
          // Register

          axios.post('/register', {
            name: this.firstName + this.lastName,
            email: this.email,
            password: this.password,
            password_confirmation: this.password,
          })
          .then(response => {
            //console.log(response);
            this.$router.push('/dashboard');
          })
          .catch(error => {
            alert(error.response.data.message)
          })

      });

    }
  }
}
</script>
