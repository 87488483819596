<template>
  <div>
  <span>{{ animatedNumber }}</span>
</div>
</template>


<script>
import { gsap } from "gsap";

export default {
    name: 'animated-integer',
    props: {
      value: {
        type: Number,
        required: true
      }
    },
    computed: {
      animatedNumber: function() {
        return this.tweeningValue.toFixed(0);
      }
    },
    data() {
        return {
            tweeningValue: 300
        }
    },
    watch: {
      value: function (newValue, oldValue) {
        gsap.to(this, { duration: 0.3, tweeningValue: newValue });
      }
    },
    mounted: function () {

    },
    methods: {

    }
}
</script>

<style>
</style>
